// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import compose from 'recompose/compose'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ State
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import Video from '@bodhi-project/components/lib/video'
import '@bodhi-project/components/lib/video/style.less'

import getRandomArbitraryInt from '@bodhi-project/components/lib/methods/getRandomArbitraryInt'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'

import ProductsGrid from '../components/products-grid-template/grid'
import '../components/products-grid-template/style.less'

import HomepageSlider from '../components/homepage-slider'
import '../components/homepage-slider/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Home',
  nakedPageSlug: '',
  pageAbstract:
    'LiGHT-FiSH is an emerging Product Design studio focused on the minimalistic beauty of natural materials. Sustainable materials are carefully chosen and crafted into visionary and intelligently designed products. Many custom and limited edition designs are prototyped, tested and refined, each time telling a unique story. Much like the stages of growth of a plant, from seed to tree, LiGHT-FiSH chooses a design process that is organic and systematic.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    p1: file(relativePath: { eq: "homepage/p1.jpg" }) {
      ...max1600
    }
    p4: file(relativePath: { eq: "homepage/p4.jpg" }) {
      ...max1600
    }
    team: file(relativePath: { eq: "about-us/team/img14.jpg" }) {
      ...max1600
    }
    workshop: file(relativePath: { eq: "about-us/team/img11.jpg" }) {
      ...max1600
    }
    darkDesktop1: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img1.jpg" }
    ) {
      ...max1600
    }
    darkDesktop2: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img2.jpg" }
    ) {
      ...max1600
    }
    darkDesktop3: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img3.jpg" }
    ) {
      ...max1600
    }
    darkDesktop4: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img4.jpg" }
    ) {
      ...max1600
    }
    darkDesktop5: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img5.jpg" }
    ) {
      ...max1600
    }
    lightDesktop1: file(
      relativePath: { eq: "homepage/slider/light-desktop/img1.jpg" }
    ) {
      ...max1600
    }
    lightDesktop2: file(
      relativePath: { eq: "homepage/slider/light-desktop/img2.jpg" }
    ) {
      ...max1600
    }
    lightDesktop3: file(
      relativePath: { eq: "homepage/slider/light-desktop/img3.jpg" }
    ) {
      ...max1600
    }
    lightDesktop4: file(
      relativePath: { eq: "homepage/slider/light-desktop/img4.jpg" }
    ) {
      ...max1600
    }
    lightDesktop5: file(
      relativePath: { eq: "homepage/slider/light-desktop/img5.jpg" }
    ) {
      ...max1600
    }
    lightDesktop6: file(
      relativePath: { eq: "homepage/slider/light-desktop/img6.jpg" }
    ) {
      ...max1600
    }
    lightMobile1: file(
      relativePath: { eq: "homepage/slider/light-mobile/img1.jpg" }
    ) {
      ...max1600
    }
    lightMobile2: file(
      relativePath: { eq: "homepage/slider/light-mobile/img2.jpg" }
    ) {
      ...max1600
    }
    lightMobile3: file(
      relativePath: { eq: "homepage/slider/light-mobile/img3.jpg" }
    ) {
      ...max1600
    }
    lightMobile4: file(
      relativePath: { eq: "homepage/slider/light-mobile/img4.jpg" }
    ) {
      ...max1600
    }
    lightMobile5: file(
      relativePath: { eq: "homepage/slider/light-mobile/img5.jpg" }
    ) {
      ...max1600
    }
    darkMobile1: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img1.jpg" }
    ) {
      ...max1600
    }
    darkMobile2: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img2.jpg" }
    ) {
      ...max1600
    }
    darkMobile3: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img3.jpg" }
    ) {
      ...max1600
    }
    darkMobile4: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img4.jpg" }
    ) {
      ...max1600
    }
    darkMobile5: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img5.jpg" }
    ) {
      ...max1600
    }
    products: allResources(limit: 8, filter: { slug: { regex: "//shop//" } }) {
      edges {
        node {
          title
          slug
          lightCover {
            childImageSharp {
              gatsbyImageData(
                width: 900
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          darkCover {
            childImageSharp {
              gatsbyImageData(
                width: 900
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          variants {
            retailPrice
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------

/** Page */
const Page = (props) => {
  const {
    data: {
      products: { edges: resourcesX },
    },
  } = props

  const resources = []

  map(resourcesX, (resource) => {
    resources.push(resource.node)
  })

  // <Img
  //   fluid={props.data.p1.childImageSharp.fluid}
  //   durationFadeIn={getRandomArbitraryInt(300, 1200)}
  //   className="c550"
  // />

  return (
    <ConfigProvider prefixCls="wtf">
      <StandardPageWrapper className="home-page" seoData={seoData} {...props}>
        <HomepageSlider {...props} />
        <div className="block home left" style={{ display: 'flex' }}>
          <div
            className="hidden-on-tablet hidden-on-mobile"
            style={{
              flex: '1',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              paddingRight: '1rem',
            }}
          >
            <Img
              style={{ width: 300 }}
              fluid={props.data.p4.childImageSharp.fluid}
              durationFadeIn={getRandomArbitraryInt(300, 1200)}
            />
          </div>
          <div style={{ flex: '1', alignItems: 'center', display: 'flex' }}>
            <div>
              <h2 className="as-paragraph">
                <strong style={{ fontSize: '110%' }}>
                  LiGHT-FiSH is a product design studio with a vision to be
                  India’s leader in sustainable design
                </strong>
              </h2>
              <p>
                LiGHT-FiSH is a project serving clients in lighting, furniture
                and home appliances. The project is located in Auroville, an
                international township in Tamil Nadu, a place of research,
                collaboration and sharing of knowledge, aspiring to perfection.
              </p>
              <p>
                Much like the stages of growth of a plant, from seed to tree,
                LiGHT-FiSH chooses a design process that is organic and
                systematic. Sustainable materials are carefully chosen and
                crafted into visionary and intelligently designed products.
              </p>
            </div>
          </div>
        </div>
        <div className="block home right" style={{ display: 'flex' }}>
          <div
            style={{
              flex: '1',
              alignItems: 'center',
              display: 'flex',
              paddingRight: '1rem',
            }}
          >
            <div>
              <h2 className="as-paragraph" style={{ textAlign: 'right' }}>
                <strong style={{ fontSize: '110%' }}>
                  LiGHT-FiSH is a regenerative design project for a brighter,
                  more sustainable future
                </strong>
              </h2>
              <p style={{ textAlign: 'right' }}>
                We source our wood directly from a small forest in the North of
                Auroville called Kamataru. The fallen trees in certain sections
                of the forest are cut into logs and seasoned before being sent
                to our workshop and turned into lights.
              </p>
              <p style={{ textAlign: 'right' }}>
                Sawdust, a byproduct, is returned to Kamataru to get transformed
                into mulch and compost for the forest and the vegetable garden,
                the same biomass is returned to the land from which it was
                extracted and a full ecological cycle is completed.
              </p>
            </div>
          </div>
          <div
            className="hidden-on-tablet hidden-on-mobile"
            style={{ flex: '1', alignItems: 'center', display: 'flex' }}
          >
            <Video ratio={0.54} url="https://vimeo.com/162349935" />
          </div>
        </div>
        <h2>Featured Products</h2>
        <ProductsGrid resources={resources} />
      </StandardPageWrapper>
    </ConfigProvider>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    lightOrDarkMode: state.lightOrDarkMode,
  }),
  (dispatch) => ({
    // updateLightOrDarkMode(payload) {
    //   dispatch(updateLightOrDarkModeFx(payload))
    // },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
