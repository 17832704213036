// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import { connect } from 'react-redux'
import shuffle from 'lodash/shuffle'
import compose from 'recompose/compose'
import pure from 'recompose/pure'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Carousel from 'antd/lib/carousel'
import '@bodhi-project/antrd/lib/4.10.3/carousel/style/css'

import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import ResourceRenderer from './slide'

import ArrowLeft from '../svg/arrow-left'
import ArrowRight from '../svg/arrow-right'

import smallKey from '../../methods/smallKey'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    darkDesktop1: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    darkDesktop2: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    darkDesktop3: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    darkDesktop4: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    darkDesktop5: file(
      relativePath: { eq: "homepage/slider/dark-desktop/img5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightDesktop1: file(
      relativePath: { eq: "homepage/slider/light-desktop/img1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightDesktop2: file(
      relativePath: { eq: "homepage/slider/light-desktop/img2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightDesktop3: file(
      relativePath: { eq: "homepage/slider/light-desktop/img3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightDesktop4: file(
      relativePath: { eq: "homepage/slider/light-desktop/img4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightDesktop5: file(
      relativePath: { eq: "homepage/slider/light-desktop/img5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightDesktop6: file(
      relativePath: { eq: "homepage/slider/light-desktop/img6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightMobile1: file(
      relativePath: { eq: "homepage/slider/light-mobile/img1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightMobile2: file(
      relativePath: { eq: "homepage/slider/light-mobile/img2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightMobile3: file(
      relativePath: { eq: "homepage/slider/light-mobile/img3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightMobile4: file(
      relativePath: { eq: "homepage/slider/light-mobile/img4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    lightMobile5: file(
      relativePath: { eq: "homepage/slider/light-mobile/img5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    darkMobile1: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    darkMobile2: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    darkMobile3: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    darkMobile4: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    darkMobile5: file(
      relativePath: { eq: "homepage/slider/dark-mobile/img5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const HomepageSlider = (props) => {
  const { mediaState, lightOrDarkMode } = props
  const { isMobile = false } = mediaState
  let resources = []

  const sliderConf = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    swipeToSlide: false,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (lightOrDarkMode === 'light') {
          // Light mode, desktop
          if (isMobile === false) {
            resources = shuffle([
              {
                content: data.lightDesktop1,
              },
              {
                content: data.lightDesktop2,
              },
              {
                content: data.lightDesktop3,
              },
              {
                content: data.lightDesktop4,
              },
              {
                content: data.lightDesktop5,
              },
            ])
          }
          // Light mode, mobile
          else {
            resources = shuffle([
              {
                content: data.lightMobile1,
              },
              {
                content: data.lightMobile2,
              },
              {
                content: data.lightMobile3,
              },
              {
                content: data.lightMobile4,
              },
              {
                content: data.lightMobile5,
              },
            ])
          }
        } else {
          // Dark mode, desktop
          if (isMobile === false) {
            resources = [
              ...shuffle([
                {
                  content: data.darkDesktop2,
                },
                {
                  content: data.darkDesktop4,
                },
              ]),
              ...shuffle([
                {
                  content: data.darkDesktop1,
                },
                {
                  content: data.darkDesktop3,
                },
                {
                  content: data.darkDesktop5,
                },
              ]),
            ]
          }
          // Dark mode, mobile
          else {
            resources = shuffle([
              {
                content: data.darkMobile1,
              },
              {
                content: data.darkMobile2,
              },
              {
                content: data.darkMobile3,
              },
              {
                content: data.darkMobile4,
              },
              {
                content: data.darkMobile5,
              },
            ])
          }
        }

        return (
          <Carousel {...sliderConf} className="homepage-slider">
            {resources.map((resource, index) => (
              <ResourceRenderer
                key={smallKey()}
                resource={resource}
                loading={index === 0 ? 'eager' : 'lazy'}
              />
            ))}
          </Carousel>
        )
      }}
    />
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    lightOrDarkMode: state.lightOrDarkMode,
    mediaState: state.mediaState,
  })
  // dispatch => ({
  //   // updateLightOrDarkMode(payload) {
  //   //   dispatch(updateLightOrDarkModeFx(payload))
  //   // },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedHomepageSlider = compose(
  withState, // Add state
  pure
)(HomepageSlider)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedHomepageSlider
